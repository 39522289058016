<!--
 * ======================================
 * 说明： 为学生添加评语
 * 作者： Silence
 * 文件： remarkEdit.vue
 * 日期： 2023/8/26 0:27
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form :rules="rules" :model="form" ref="form" label-width="80px">
    <el-form-item label="评语内容" prop="content">
      <el-input :rows="5" type="textarea" v-model="form.content" :maxlength="maxlength" show-word-limit></el-input>
    </el-form-item>
    <el-form-item>
      <el-dropdown trigger="click">
        <!--        <el-button split-button type="primary">-->
        <!--          评语库<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--        </el-button>-->
        <el-dropdown-menu slot="dropdown" style="max-height: 330px;overflow-x:auto;">
          <el-dropdown-item v-for="item in commentList" :key="item.id" :label="item.title" :value="item.id"
            @click.native="changeLibrary(item.title)">{{ item.title }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      maxlength: '',
      library: '',
      commentList: [],
      form: {
        semesterId: '',
        classId: '',
        studentId: '',
        content: '',
      },
      rules: {
        content: [
          { required: true, message: '请输入评语内容', trigger: ['blur', 'change'] },
        ]
      },
    }
  },
  computed: {},
  async mounted() {
    await this.handleLoadComment();

    await this.getLoginInfo();

    this.$nextTick(() => {
      this.form = {
        semesterId: this.value.semester_id,
        classId: this.value.class_id,
        studentId: this.value.student_id,
        content: this.value.content,
      }
    })
  },
  methods: {
    async handleLoadComment() {
      await this.$cloud.get('comment/list', {
        page: 1,
        size: 99999,
      }).then(res => {
        this.commentList = res.children || []
        if (this.commentList.length) {
          this.commentList = this.commentList.map(item => {
            return {
              ...item,
              title: item.content?.length > 12 ? item.content.slice(0, 12) + '...' : item.content
            }
          })
        }
      }).catch(() => { })
    },

    async getLoginInfo() {
      await this.$cloud.get('account/detail').then(res => {
        if (res) {
          this.handleLoadMax(res)
        }
      })
    },
    // 获取评语字数限制
    handleLoadMax(data) {
      this.$cloud.get('subject/detail', { id: 1 }).then(res => {
        this.maxlength = res.max || 9999;
      })
    },

    changeLibrary(content) {
      this.form.content = content
    },
    handleSubmit() {
      console.log('xxx:', this.form);
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$cloud.post('comment/student/create', this.form).then((res) => {
              resolve(true)
            }).catch(() => {
              reject(false)
            })
          } else {
            reject(false)
          }
        })
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>
