<!--
 * ======================================
 * 说明： 教师端 - 评语管理
 * 作者： Silence
 * 文件： remark.vue
 * 日期： 2023/8/3 0:26
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <div class="mx-6 pl-4 pt-8">
      <el-form :inline="true" :model="formSearch" class="demo-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleRefresh" @refresh="handleRefresh"></SemesterClassSelect>
      </el-form>
    </div>
    <Scrollbar>
      <Table :options="handleOptions" :loadData="handleLoadData" ref="table">
        <template #action>
          <div>
            <el-button type="success" icon="el-icon-download" @click="handleExportXlsx">导出</el-button>
            <el-upload
                action=""
                :show-file-list="false"
                style="display: contents"
                accept=".xls,.XLS,.xlsx,.XLSX"
                :http-request="handleUploadXlsx"
            >
              <el-button icon="el-icon-upload2" type="primary" class="ml-1"
              >导入
              </el-button>
            </el-upload>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleSetRemark(row)">设置评语</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from "./components/remarkEdit.vue"
import mixin from "@/mixin/execlExportImport";

export default {
  name: "medal",
  mixins: [mixin],
  data() {
    return {
      tableList: [],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        semesterId: '',
        number: '',
        realName: '',
        classId: '',
      },
    };
  },
  mounted() {
  },
  methods: {
    handleLoadDataInfo() {
      this.handleRefresh();
    },
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teaching/comment").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("comment/student/list", {size, page, ...this.formSearch}).then(res => {
        this.commentList = res.children || []
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 设置评语
    handleSetRemark(row) {
      this.$cloud.dialog({
        title: '设置评语',
        data: row,
        component: edit,
        success: '设置成功!',
        warning: '设置失败!',
        customClass: 'remark-dialog-class',
        refresh: this.handleRefresh
      })
    },

    // 导出文件
    handleExportXlsx() {
      this.$cloud.get("comment/student/list", {page: 1, size: 99999, ...this.formSearch}).then(res => {
        let table = res.children || []
        // 一级表头
        let tHeader = ['学期ID', "班级ID", "学号", "姓名", "评语内容"];
        let fields = ["semester_id", "class_id", "number", "real_name", "content"];
        let formatJson = (fliterVal, tableData) => {
          tableData.map((v) => fliterVal.map((j) => v[j]));
          return tableData.map((v) => fliterVal.map((j) => v[j]));
        };
        let excelData = formatJson(fields, table);
        this.exportElxs({
          header: tHeader, //这里应该是算第二行的表头
          data: excelData, //数据
          filename: `学生列表评语`,
          autoWidth: true,
          bookType: "xlsx",
        });
      })
    },


    // 上传文件
    handleUploadXlsx(e) {
      this.httpRequest(e, (data) => {
        if (data && data.length) {
          let params = {
            semesterId: data[0]['学期ID'],
            classId: data[0]['班级ID'],
            studentList: data.map(item => {
              return {
                number: item['学号'],
                remark: item['评语内容'],
              }
            })
          }

          this.$cloud.post('comment/student/import', params).then(res => {
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },

    // 重置
    handleReset() {
      this.classId = []
      this.formSearch = {
        semesterId: '',
        number: '',
        realName: '',
        classId: '',
      }
    }
  },
};
</script>
<style lang="scss">
.remark-dialog-class {
  width: 640px;
}
</style>
